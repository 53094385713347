import React from "react";
import { StaticImage } from "gatsby-plugin-image";

// Background Dark : #191918
// Background Light : #FFFCF4

// Primary (Blue): #86BBD8
// Darken-blue: #354A56
// Darken green: #355652
// Black-text: #0F1918

export default function Layout({ children }) {
  return (
    <div id="wrapper">
      <div>
        <div role="navigation">
          <a href="/" className="headline text-1xl p-4 text-green-200">
            Planet <br />
            &nbsp;&nbsp;Advocate <small className="text-deep-blue">(.eu)</small>
          </a>
          <div className="logo">
            <StaticImage
              src="../images/icon.png"
              alt="Planet Advocate Logo Transparent"
              className="static-image"
            />
          </div>
          <div className="browse">
            {/* <button className="text-green-200 underline underline-offset-2 transition-all hover:underline-offset-4">
              Read Latest⎬
            </button> */}
          </div>
        </div>

        <main>
          <aside>
            <div>
              <ul></ul>
            </div>
          </aside>
          {children}
        </main>
        <footer className="justify-left flex align-middle text-white-blue">
          <p>© A Planet Advocate {new Date().getFullYear()}</p>
        </footer>
      </div>
    </div>
  );
}
