exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-news-index-js-content-file-path-opt-buildhome-repo-content-ca-vaux-bien-un-peu-de-soupe-mdx": () => import("./../../../src/templates/news/index.js?__contentFilePath=/opt/buildhome/repo/content/ca-vaux-bien-un-peu-de-soupe.mdx" /* webpackChunkName: "component---src-templates-news-index-js-content-file-path-opt-buildhome-repo-content-ca-vaux-bien-un-peu-de-soupe-mdx" */),
  "component---src-templates-news-index-js-content-file-path-opt-buildhome-repo-content-cop-vingt-sept-plus-deux-degrees-mdx": () => import("./../../../src/templates/news/index.js?__contentFilePath=/opt/buildhome/repo/content/cop-vingt-sept-plus-deux-degrees.mdx" /* webpackChunkName: "component---src-templates-news-index-js-content-file-path-opt-buildhome-repo-content-cop-vingt-sept-plus-deux-degrees-mdx" */),
  "component---src-templates-news-index-js-content-file-path-opt-buildhome-repo-content-inflation-climatique-mdx": () => import("./../../../src/templates/news/index.js?__contentFilePath=/opt/buildhome/repo/content/inflation-climatique.mdx" /* webpackChunkName: "component---src-templates-news-index-js-content-file-path-opt-buildhome-repo-content-inflation-climatique-mdx" */),
  "component---src-templates-stories-index-js-content-file-path-opt-buildhome-repo-content-histoire-dun-chat-mdx": () => import("./../../../src/templates/stories/index.js?__contentFilePath=/opt/buildhome/repo/content/histoire-dun-chat.mdx" /* webpackChunkName: "component---src-templates-stories-index-js-content-file-path-opt-buildhome-repo-content-histoire-dun-chat-mdx" */),
  "component---src-templates-stories-index-js-content-file-path-opt-buildhome-repo-content-pas-doptimisme-resigne-mdx": () => import("./../../../src/templates/stories/index.js?__contentFilePath=/opt/buildhome/repo/content/pas-doptimisme-resigne.mdx" /* webpackChunkName: "component---src-templates-stories-index-js-content-file-path-opt-buildhome-repo-content-pas-doptimisme-resigne-mdx" */)
}

